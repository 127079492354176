<template>
  <div>
    <b-card header="Opciones de Filtro" class="card-filter">
      <b-btn class="filter-collapse" 
          variant="primary" 
          size="sm"
          @click.prevent="showOpcionesFiltro= !showOpcionesFiltro">
        <i :class="showOpcionesFiltro ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
      </b-btn>
      <b-collapse id="filtro" class="mt-2" v-model="showOpcionesFiltro">
        <b-form>
          <app-tipo-propiedad
            label="Tipo de propiedad"
            :labelCols="labelCols"
            :nombre="tipoPropiedadNombre"
            :lista="tiposPropiedadesList"
            :nullDisabled="false"
            @updated="tipoPropiedadActualizado">
          </app-tipo-propiedad>

          <b-form-group
            label="Título"
            label-for="titulo"
            :label-cols="labelCols">
            <b-form-input 
              id="titulo"
              v-model.trim="filtro.titulo"
              type="text">
            </b-form-input>
          </b-form-group>

          <b-form-group
            label="Dirección"
            label-for="direccion"
            :label-cols="labelCols">
            <b-form-input 
              id="direccion"
              v-model.trim="filtro.direccion"
              type="text">
            </b-form-input>
          </b-form-group>

          <b-form-group
            label-for="publicadas"
            :label-cols="labelCols">
            <b-form-checkbox 
                id="publicadas"
                v-model="filtro.publicadas"
                :value="true"
                :unchecked-value="false">
                Sólo Publicadas
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            label-for="destacadas"
            :label-cols="labelCols">
            <b-form-checkbox 
                id="destacadas"
                v-model="filtro.destacadas"
                :value="true"
                :unchecked-value="false">
                Sólo Destacadas
            </b-form-checkbox>
          </b-form-group>
        </b-form>
        <div class="footer">
          <b-button size="sm" variant="primary" class="footer-button"
            @click.prevent="filtrar">Filtrar</b-button>
          <b-button size="sm" variant="primary" class="footer-button" 
            @click.prevent="limpiarFiltro">Limpiar</b-button>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import TipoPropiedad from '../shared/abmSelect.vue';
import moment from 'moment';

export default {
  name: 'PropiedadesListFiltro',
  components: {
    appTipoPropiedad: TipoPropiedad,
  },
  props: {
    titulo: {
      type: String,
      default: ''
    },
    direccion: {
      type: String,
      default: ''
    },
    tipoPropiedad: {
      type: Object,
      default: null,
    },
    publicadas: {
      type: Boolean,
      default: false
    },
    destacadas: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filtro: {
        titulo: '',
        direccion: '',
        tipoPropiedad: null,
        publicadas: false,
        destacadas: false
      },
      showOpcionesFiltro: Boolean(false),
      labelCols: 2,
      tiposPropiedades: [],
      tiposPropiedadesList: []
    }
  },
  computed: {
    tipoPropiedadNombre() {
      if (this.filtro.tipoPropiedad) {
        if (this.filtro.tipoPropiedad.nombre) {
            return this.filtro.tipoPropiedad.nombre.trim();
        }
      }
      return null;
    }
  },
  created() {
    this.getDatosAdicionales();

    this.filtro.titulo = this.titulo;
    this.filtro.direccion = this.direccion;
    this.filtro.tipoPropiedad = this.tipoPropiedad;
    this.filtro.publicadas = this.publicadas;
    this.filtro.destacadas = this.destacadas;
  },
  methods: {
    getDatosAdicionales() {
      this.$store.dispatch('tiposPropiedades/getList')
        .then(() => {
          this.tiposPropiedades = this.$store.getters['tiposPropiedades/list'];
          this.tiposPropiedadesList = this.$store.getters['tiposPropiedades/selectList'];
        })
        .catch(error => this.showErrorMsj(error));
    },
    tipoPropiedadActualizado(tipoPropiedadNombre) {
      let tipoPropiedad;
      if (tipoPropiedadNombre) {
        if (tipoPropiedadNombre != '') {
          tipoPropiedad = this.tiposPropiedades.find(tipoPropiedad => tipoPropiedad.nombre.trim() == tipoPropiedadNombre);
          if (tipoPropiedad) {
            this.filtro.tipoPropiedad = tipoPropiedad;
          }
        }
      }
    },
    filtrar() {
      this.$emit('filtrar', this.filtro);
    },
    limpiarFiltro() {
      this.filtro.titulo = '';
      this.filtro.direccion = '';
      this.filtro.tipoPropiedad = null;
      this.filtro.publicadas = false;
      this.filtro.destacadas = false;
    },
  },
}
</script>
