<template>
  <div>
    <b-row>
      <b-col cols="12" xl="12">
        <app-filtro
          :titulo="titulo"
          :direccion="direccion"
          :tipoPropiedad="tipoPropiedad"
          :publicadas="publicadas"
          :destacadas="destacadas"
          @filtrar="filtrar">
        </app-filtro>  
      </b-col>      
    </b-row>

    <b-row>
      <b-col cols="12" xl="12">  
        <transition name="slide">
        <b-card header="Listado de Propiedades" >
          <b-table hover
              responsive="sm" 
              class="registro"
              :busy="tableBusy"
              :items="propiedades" 
              :fields="itemFields"
              @row-clicked="consultarPropiedad">
            <div slot="table-busy" class="text-center">
              <b-spinner class="table-spinner" label="Spinning" />
            </div>              
            <template slot="destacada" slot-scope="data">
              {{ data.item.destacada ? 'Si' : 'No' }}
            </template>
            <template slot="publicada" slot-scope="data">
              {{ data.item.publicada ? 'Si' : 'No' }}
            </template>

            <template slot="opciones" slot-scope="data">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template slot="button-content">
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item @click.stop="editarPropiedad(data.item)">
                  <i class="fa fa-edit"></i>Editar Datos de la Propiedad</b-dropdown-item>
                <!-- <b-dropdown-item @click.stop="editarImagenesPropiedad(data.item)">
                  <i class="fa fa-edit"></i>Consultar Imagenes</b-dropdown-item> -->
              </b-dropdown>
            </template>
          </b-table>
          <nav>
            <b-pagination size="sm" 
              :total-rows="totalRegistros" 
              :per-page="registrosPorPagina" 
              v-model="paginaActual" prev-text="Prev." next-text="Prox."/>
          </nav>
        </b-card>
        </transition>
      </b-col>
    </b-row>

    <div class="fixed-btn">
      <div class="list-dropdown-btn">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template slot="button-content">
            <i class="fa fa-ellipsis-v fa-2x" aria-hidden="true"></i>
          </template>
          
          <b-dropdown-item @click.stop="agregarPropiedad">
            <i class="fa fa-plus"></i>Agregar Propiedad</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>

    <app-modal-confirm
      :visible="confirmModalVisible"
      :mensaje="confirmMsj"
      :okTitle="confirmOkTitle"
      :cancelTitle="confirmCancelTitle"
      @confirmOk="confirmOk"
      @confirmCancel="confirmCancel">
    </app-modal-confirm>
  </div>
</template>

<script>
import Filtro from  './listFiltro.vue';
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "propiedades/getField",
  mutationType: "propiedades/updateField",
});


export default {
  name: 'PropiedadesList',
  components: {
    appFiltro: Filtro,
  },
  data () {
    return {
      itemFields: [
        // { key: 'titulo', _classes: 'font-weight-bold' },
        { key: 'titulo' },
        { key: 'direccion' },
        { 
          key: 'tipoPropiedad',
          label: 'Tipo'
        },
        { key: 'destacada' },
        { key: 'publicada' },
        // { key: 'status' }
        { 
          key: 'opciones',
          label: '',
          class: 'text-center'
        },
      ],

      tableBusy: false
    }
  },
  computed: {
    ...mapFields([
      'propiedades',
      'paginaActual',
      'registrosPorPagina',
      'totalRegistros',
      'totalPaginas',
      'titulo',
      'direccion',
      'tipoPropiedad',
      'publicadas',
      'destacadas'
    ]),
  },
  created() {
    this.getItems();
  },
  methods: {
    filtrar(filtro) {
      this.tipoPropiedad = filtro.tipoPropiedad;
      this.titulo = filtro.titulo;
      this.direccion = filtro.direccion;
      this.publicadas = filtro.publicadas;
      this.destacadas = filtro.destacadas;
      this.paginaActual = 1;
      this.getItems();  
    },
    getItems() {
      this.tableBusy = true;
      this.$store.dispatch('propiedades/getPropiedades')
        .then(() => this.tableBusy = false)
        .catch(error => this.showErrorMsj(error));
    },
    agregarPropiedad () {
      this.$router.push({ name: 'Propiedad', 
                    params: { id: '' }});
    },
    editarPropiedad (item) {
      this.$router.push({ name: 'Propiedad', 
                    params: { id: item.id }});
    },
    consultarPropiedad (item) {
      this.$router.push({ name: 'PropiedadDatos', 
                    params: { id: item.id }});
    },
    // editarImagenesPropiedad (item) {
    //   this.$router.push({ name: 'PropiedadImagenes', 
    //                 params: { id: item.id }});
    // },
  },
  watch: {
    'paginaActual' : function(newVal, oldVal) {
      if ( newVal != 0 && oldVal != 0) {
        this.getItems() 
      }
    },    
  }    
}
</script>
