<template>
  <b-form-group
    :label=" showLabel? label : ''"
    label-for="nombre"
    :label-cols="labelCols"
    :description="description"
    horizontal>
    <b-form-select 
      :id="selectId"
      v-model="propiedad" 
      v-if="!readOnly"
      :options="lista" 
      :state="valido"
      :disabled="disabled"
      class="mb-3"
      @blur.native="validate">
      <template slot="first">
        <option :value="null" :disabled='nullDisabled'>Seleccione un {{ label }}</option>
      </template>
    </b-form-select>                 
    <b-form-invalid-feedback id="feedback">
      {{ msjInvalido }}
    </b-form-invalid-feedback>
    <b-form-input 
      id="nombre"
      v-if="readOnly"
      :value= "nombre" 
      type= "text"
      readonly>
    </b-form-input>
    <slot></slot>
  </b-form-group>
</template>

<script>
export default {
  props: {
    selectId: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    nombre: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false    
    },
    valido: {
      type: Boolean,
      default: null    
    },
    lista: {
      type: Array,
      default: null
    },
    msjInvalido: {
      type: String,
      default: ''
    },
    labelCols: {
      type: Number,
      default: 1
    },
    nullDisabled: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    description: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      propiedad: '',
      updating: false
    }
  },
  created() {
      this.updating = true;
      this.propiedad = this.nombre;
      this.updating = false;
  },
  computed: {
  },     
  methods: {
    validate() {
      this.$emit('validate');
    }
  },

  watch: {
    'nombre' : function(newVal, oldVal) {
      this.updating = true;
      this.propiedad = newVal;
      this.updating = false;
    },
    'propiedad' : function(newVal, oldVal) {
      if (newVal != oldVal && !this.updating) {
        this.$emit('updated', this.propiedad);
      }
    },
  }    
}
</script>

<style>
</style>
